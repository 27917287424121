import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1 className="home-heading">Adebayọ Olusọla</h1>
      <ul className="home-links">
        <li><a href="https://greenfields.ng" target="_blank">Greenfields.</a></li>
        <li><a href="https://phoenixdeluxe.ng" target="_blank">Phoenix</a></li>
        {/* <li><a href="#link3">Link 3</a></li> */}
      </ul>

      
    </div>
  );
};

export default Home;
