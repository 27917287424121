import './App.css';
import Home from './components/Home';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <div>
        <Home/>
      </div>
      <div className="Footer">
        <Footer/>
      </div>
    </div>
  );
}

export default App;
